<template>
  <div id="app">
    <v-app>
      <v-main>
        <img src="/images/logo.webp" class="logo">
        <v-snackbar
          v-model="isIE"
          color="orange"
          rounded="pill"
          centered
        >
          Leider wird unser Konfigurator von Ihrem Webbrowser nicht vollständig unterstützt.
          Bitte nutzen Sie Edge, Chrome oder Firefox, um alle Funktionen nutzen zu können.
          <template v-slot:action="{ attrs }">
            <v-btn
              color="black"
              text
              v-bind="attrs"
              @click="success = false"
            >
              Ok.
            </v-btn>
          </template>
        </v-snackbar>
        <v-container fluid class="grey lighten-5">
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="6"
              v-if="!showDrawing"
            >
              <ProductInfoTab />
              <ShoppingCart />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              v-if="showDrawing"
            >
              <DrawingTab />
              <ShoppingCart />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <Guide />
            </v-col>
          </v-row>
        </v-container>
        <v-snackbar
          v-model="snackbar.active"
          :timeout="snackbar.timeout"
          :color="snackbar.color"
          rounded="pill"
          centered
        >
          {{ snackbar.message}}
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.active = false">
              Ok
            </v-btn>
          </template>
        </v-snackbar>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import Guide from './components/guide/Guide.vue'
import DrawingTab from './components/presentation/DrawingTab.vue'
import ProductInfoTab from './components/presentation/ProductInfoTab.vue'
import ShoppingCart from './components/presentation/ShoppingCart.vue'

export default {
  name: 'App',
  components: {
    DrawingTab,
    ProductInfoTab,
    Guide,
    ShoppingCart,
  },
  data () {
    return {
      isIE: false,
    }
  },
  computed: {
    ...mapGetters({
      isProductConfigured: 'isProductConfigured',
      partList: 'partListForSides',
    }),
    ...mapState({
      anchoring: state => state.anchoring,
      presentationTab: state => state.presentationTab,
      shaping: state => state.shaping,
      guideTab: state => state.guideTab,
      additionalArticleList: state => state.additionalArticleList,
      snackbar: state => state.snackbar,
    }),
    hasDifferentAnchorings() {
      return this.product && ['product-fussgaengerschutz', 'product-wandschutz'].includes(this.product.key)
    },
    showDrawing() {
      return !(!this.isProductConfigured || (!this.anchoring && this.hasDifferentAnchorings) || !this.shaping) && this.guideTab === 0
    },
    additionalArticleListSorted() {
      return [...this.additionalArticleList].sort((a,b) => a-b)
    },
    partListSorted() {
      return [...this.partList].sort((a,b) => a-b)
    }
  },
  watch: {
    shaping() {
      if(this.isProductConfigured && this.shaping && (this.anchoring || !this.hasDifferentAnchorings)) {
        setTimeout(() => this.$root.$emit('fitDrawing'), 500);
      }
    }
  },
  mounted() {
    var ua = window.navigator.userAgent;
    this.isIE = /MSIE|Trident/.test(ua);
  },
}
</script>
<style scoped>
  .logo {
    position: absolute;
    left: 16px;
    top: 16px;
    max-height: 40px;
    max-width: 17%;
    z-index:100;
  }
</style>