<template>
  <div>
    <p>
      Herzlichen Glückwunsch! Sie haben Ihren neuen, individuellen Rammschutz erfolgreich konfiguriert.
      Klicken Sie auf "Senden / Drucken", um Ihre Konfiguration zu sichern und via E-Mail zu erhalten.
      Auf dieser PDF finden Sie eine Anfragenummer.
      Senden Sie uns gerne eine Anfrage unter Angabe dieser Nummer, um ein individuelles Angebot zu Ihrer Konfiguration zu erhalten.
    </p>
    <v-row>
      <v-col>
        <v-btn
          :disabled="loading"
          elevation="2"
          @click="navigateToAdditionalProducts"
          :loading="loading"
        >
          Auswahl ändern
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          :disabled="!valid || loading"
          :color="success ? 'green' : 'primary'"
          style="float:right"
          elevation="2"
          @click="() => validate() ? downloadQuote() : false"
          :loading="loading"
        >
          <template v-if="!success">Senden / Drucken</template>
          <template v-else><v-icon>mdi-check</v-icon></template>
        </v-btn>
      </v-col>
    </v-row>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >

        <v-text-field
          v-model="contact.company"
          :rules="requiredRules"
          label="Firmenname *"
          required
        ></v-text-field>

        <v-text-field
          v-model="contact.name"
          label="Ansprechpartner *"
          :rules="requiredRules"
          required
        ></v-text-field>

        <v-text-field
          v-model="contact.phone"
          :rules="phoneRules"
          label="Telefonnummer *"
          required
        ></v-text-field>

        <v-text-field
          v-model="contact.email"
          :rules="emailRules"
          label="E-Mail-Adresse *"
          required
        ></v-text-field>

        <v-text-field
          v-model="contact.address"
          label="Straße & Nr. *"
          :rules="requiredRules"
          required
        ></v-text-field>

        <v-row :justify="'center'">
          <v-col>
            <v-text-field
              v-model="contact.plz"
          :rules="requiredRules"
              label="PLZ *"
          required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="contact.city"
              label="Stadt *"
          :rules="requiredRules"
          required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-textarea
          v-model="contact.message"
          label="Ihre Nachricht an uns *"
          :rules="requiredRules"
          auto-grow
          required
        ></v-textarea>

        <!--<v-checkbox
          v-model="contact.dsgvo" 
          :rules="[v => !!v || 'Bitte akzeptieren Sie die Datenschutzbestimmungen.']"
          label="Datenschutzeinwilligung, Lorem ipsum?"
          required
        ></v-checkbox>-->

        <p class="caption">* Pflichtfeld</p>
      </v-form>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import axios from 'axios'

export default {
  name: 'Checkout',
  components: {
  },
  props: {
  },
  data () {
    return {
      loading: false,
      success: false,
      quote_number: '',
      valid: true,
      emailRules: [
        v => (!!v && /.+@.+\..+/.test(v)) || 'Bitte geben Sie eine gültige E-Mailadresse ein.',
      ],
      requiredRules: [
        v => !!v || 'Bitte füllen Sie dieses Feld aus.',
      ],
      phoneRules: [
        v => !!v || 'Bitte füllen Sie dieses Feld aus.',
        v => v && v.length > 4 || 'Bitte geben Sie mindestens 5 Zeichen ein.',
        v => /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(v) || 'Bitte geben Sie eine gültige Telefonnummer ein.',
      ],
      contact: {
        dsgvo: false,
      }
    }
  },
  computed: {
    ...mapGetters({
      isProductConfigured: 'isProductConfigured',
      sides: 'computedSides',
      partList: 'partListForSides',
      cuttedParts: 'cuttedParts',
    }),
    ...mapState({
      additionalArticleList: state => state.additionalArticleList,
      anchoring: state => state.anchoring,
      drawingSvg: state => state.drawingSvg,
      shaping: state => state.shaping,
      product: state => state.product,
      productDetails: state => state.productDetails,
      configurationAmount: state => state.configurationAmount,
    })
  },
  methods: {
    ...mapMutations([
      'setGuideStep',
      'setGuideTab',
      'setSnackbar',
    ]),
    navigateToAdditionalProducts() {
      this.setGuideTab(1);
    },
    validate () {
      return this.$refs.form.validate()
    },
    downloadQuote() {
      this.loading = true;
      let sendRequest = () => {
        axios({
          method: 'post',
          url: window.QUOTE_URL || '/quote.php',
          responseType: 'arraybuffer',
          data: {
            additionalArticleList: this.additionalArticleList,
            anchoring: this.anchoring,
            partList: this.partList,
            product: this.product,
            productDetails: this.productDetails,
            shaping: this.shaping,
            sides: this.sides,
            svg: this.drawingSvg,
            contact: this.contact,
            cuttedParts: this.cuttedParts,
            configurationAmount: this.configurationAmount,
          }
        }).then(response => {
          this.loading = false;
          this.quote_number = response.headers['x-quote-no'];
          this.success      = true;
          this.setSnackbar({
            active: true,
            timeout: 10000,
            color: 'green',
            message: 'Vielen Dank! Ihre Anfrage ' + this.quote_number + ' wurde Ihnen per Mail zugestellt.'
          });
          /*
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'Anfrage' + ((this.quote_number) ? ' ' + this.quote_number : '') + '.pdf'
          link.click()
          */
        }).catch((error) => {
          this.loading = false;
          console.log(error);
          alert('Anfrage konnte nicht erstellt werden.')
        })
      };
      if(this.shaping) {
        this.$root.$emit('fitDrawing');
        this.$root.$once('fitDrawingDone', sendRequest);
      }else{
        sendRequest();
      }
    }
  },
}
</script>

<style scoped>
</style>
